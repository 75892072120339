import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import PageComponent from '../pagesComponents/pageWithCards';

const NoMoreViolencePage = ({ data, location }) => (
  <Layout location={location}>
    <PageComponent data={data} messageId="noMoreViolence" />
  </Layout>
);

NoMoreViolencePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default NoMoreViolencePage;

export const query = graphql`
query enNoMoreViolenceQuery
{
  allMarkdownRemark(
    filter: { 
      frontmatter: {
        category: { eq: "noMoreViolence" },
        language: { eq: "en" } 
      }
    }
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 1000
  ) {
    edges {
      node {
        frontmatter {
          date,
          title,
          image,
          language,
          previewText,
          category
        }
      }
    }
  }
}
`;
